/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  CopyIcon,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { SVG } from "../../../icon/svg.file";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import qrColor from "../../../images/qrcolor.png";
import qr from "../../../images/qr1.png";
import link from "../../../images/link.png";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import GallerySlider from "../../../components/slider";
import Modal from "../../../components/modal";
import QRLarge from "../../../images/qr.png";
import facebook from "../../../images/f.png";
import whatsApp from "../../../images/w.png";
import Linkdin from "../../../images/in.png";
import Email from "../../../images/e.png";
import Tel from "../../../images/tel.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import BarChart from "../../../components/chart/BarChart";
import * as global from "../../../constant/global";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import {
  callHttpRequest,
  methodType,
} from "../../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../../utility-files/api-caller/CommonRequest";
import MapComponent from "./mapView";
import {
  getDateFormat,
  getDateWithTimeFormat,
} from "../../../utility-files/date-util/DateHandling";
import { isIntegerValue } from "../../../utility-files/data-util/DataHandler";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  setBillBoardData,
  billBoardDetail,
  userData,
} from "../../../redux/reduxSlice";
import * as StorageHandling from "../../../utility-files/storage-util/StorageHandling";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import { saveAs } from "file-saver";
import { toPng, toSvg } from "html-to-image";
import { formatNumber } from "../../../utility-files/data-util/DataHandling";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SevenBySevenGrid from "./SevenGridBox";
import constants from "../../../new redux/Types/actionTypes";
import action from "../../../new redux/Action";
import axios from "axios";
import schedule from "node-schedule";
import moment from "moment";
import { downloadFile } from "../../../utility-files/helper-function/HelperFunction";
import { useTranslation } from "react-i18next";
import { isPlatform } from "@ionic/react";
import { FidgetSpinner } from "react-loader-spinner";

export default function Details() {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const [QRCode, setQRCode] = useState(false);
  const [qrSize, setQrSize] = useState(300);
  const [Social, setSocial] = useState(false);
  const clientUrl = process.env.REACT_APP_CLIENT_APP;
  const billBoardDetail = useSelector((state) => state.billBoardDetail);
  const [cameraBillBoard, setCameraBillBoard] = useState();
  const [snapData, setSnapData] = useState({ id: "" });
  const [pending, setPending] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [loading, setLoading] = useState(true); // State to manage loading spinner
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const BillBoardData = useSelector((state) => state.BillBoardData);
  const heatMapDetail = useSelector((state) => state.heatMapDetail);
  const UserData = useSelector((state) => state.UserData);
  const [boardData, setBoardData] = useState(BillBoardData);
  const Remarks = billBoardDetail?.data?.remarks;
  const spotsPerDay = billBoardDetail?.data?.spots_per_Ad_per_day || 0;
  const Name = useSelector((state) => state.billboardName);
  const qrCodeRef = React.useRef();
  const playerRefs = useRef([]);
  const sliderRef = useRef(null);
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const countryName =
    localStorage.getItem(StorageHandling.storageKey.USERDATA) ||
    sessionStorage.getItem(StorageHandling.storageKey.USERDATA);

  const handleDownload = () => {
    let canvasimage = document.querySelector("#qr-code");
    // console.log("\n\n<Details> QRCODE", canvasimage);
    var data = canvasimage.toBlob((blob) => downloadFile(blob, "qr-code.png"));
  };

  const NameData = boardData?.billboardName;
  const dynamicData = billBoardDetail?.data?.views_impression || {};
  const highestRushHour = billBoardDetail?.data?.manual
    ? billBoardDetail?.data?.rushHour?.from
      ? [
          billBoardDetail?.data?.rushHour.from.toString() +
            "-" +
            billBoardDetail?.data?.rushHour.to.toString(),
        ]
      : ["NA"]
    : Object.entries(dynamicData).reduce(
        (highest, current) => {
          return current[1] > highest[1] ? current : highest;
        },
        ["", -Infinity]
      );

  let fileName = null;
  if (billBoardDetail && billBoardDetail?.data) {
    if (billBoardDetail?.data?.images) {
      fileName = billBoardDetail?.data?.images;
    }
  }

  const adsVideo = `${global.BASE_URL}/media/videos/${Name}.mp4`;
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    // Check if the video exists
    setVideoError(false);
  }, [adsVideo]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setBoardData(newValue);
    dispatch(action(constants.BillBoardData, newValue));
    dispatch(action(constants.billboardName, newValue?.billboardName));
    navigate(`/billboard/details`);
    getBoardDetail(newValue?.billboardName || Name);
    fetchLogs(newValue?.billboardName || Name);
  };

  useEffect(() => {
    getBoardDetail();
    getBoardCamera();
    if (isAuthenticated) {
      fetchLogs();
    }
  }, []);

  const handleSocial = () => {
    setSocial(true);
    handleLink(); // Ensure handleLink is called correctly
  };

  const handleQRCode = () => {
    setQRCode(true);
  };

  const handleClose = () => {
    setQRCode(false);
    setSocial(false);
  };

  const handleLink = () => {
    if (isAuthenticated) {
      snapLink();
    }
  };

  const snapLink = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", billBoardDetail?.data?._id);

      const request = getRequestForApi(
        global.SNAP + `${billBoardDetail?.data?._id}`,
        formDataToSend,
        methodType.POST
      );
      const response = await callHttpRequest(request);

      if (response?.status === 200 || response?.status === 201) {
        setSnapData(response.data); // Make sure to set the snap data correctly
        toast.success(response.success);
      } else {
        // console.error("Unexpected response status:", response.status);
      }
    } catch (err) {
      console.error("Error occurred:", err);
    } finally {
    }
  };

  const getBoardDetail = async (NameData) => {
    setPending(true);
    let request;
    request = getRequestForApi(
      global.GET_ALL_BILLBOARD_DETAILS + `${NameData || Name}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          dispatch(action(constants.billBoardDetail, response?.data));
          setPending(false);
        }
      })
      .catch((err) => {});
    setPending(false);
  };

  const getBoardCamera = async () => {
    let request;
    request = getRequestForApi(
      global.GET_BILLBOARD_CAMERA + `${countryName}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          if (response?.data?.data?.docs) {
            let dataList = response?.data?.data?.docs;
            setCameraBillBoard([...dataList]);
            setBoardData(dataList.find((dd) => dd.billboardName == Name));
          }
        }
      })
      .catch((err) => {});
  };

  const billboardCount = [
    {
      id: 1,
      title: highestRushHour[0],
      icon: SVG.Clock,
      label: t("Billboard_index.typography.rushHour"),
    },
    {
      id: 2,
      title: formatNumber(billBoardDetail?.data?.weekly),
      icon: SVG.Eye,
      label: t("Billboard_index.typography.week"),
    },
    {
      id: 3,
      title: formatNumber(billBoardDetail?.data?.monthly),
      icon: SVG.Eye,
      label: t("Billboard_index.typography.month"),
    },
    {
      id: 4,
      title: formatNumber(billBoardDetail?.data?.yearly),
      icon: SVG.Eye,
      label: t("Billboard_index.typography.year"),
    },
    {
      id: 5,
      title:
        billBoardDetail &&
        billBoardDetail.data &&
        billBoardDetail.data.spots_per_Ad_per_day &&
        (() => {
          if (
            billBoardDetail.data.activeAds &&
            billBoardDetail.data.activeAds.length > 0
          ) {
            return billBoardDetail?.data?.activeAds?.length;
          }
          return 0;
        })(),
      icon: SVG.ED,
      label: t("Billboard_index.typography.activeAd"),
    },
    {
      id: 6,
      title: formatNumber(billBoardDetail?.data?.spots_per_ad_per_day),
      icon: SVG.Sound,
      label: t("Billboard_index.typography.spot_ad_daily"),
    },
  ];
  const MenuProps = {
    PaperProps: {
      sx: {
        background: "#0E0E0E",
        "& .MuiList-root": {
          padding: "15px !important",
          color: "#fff",

          "& .MuiButtonBase-root": {
            background: "#282520",
            mb: 1,
            borderRadius: "5px",
          },
        },
      },
    },
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${global.BASE_URL}/${snapData?.snap_id}`)
      .then(() => {
        toast.success("Link copied successfully!", {});
      })
      .catch((error) => console.error("Error copying link: ", error));
  };

  const settings = {
    dots: true,
    infinite: false, // Allows looping back to the first video
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentVideoIndex(index),
  };

  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % billBoardDetail.data.activeAds.length; // Loop back to the first video
      sliderRef.current.slickGoTo(nextIndex); // Move to the next slide
      return nextIndex;
    });
  };

  useEffect(() => {
    // Automatically play the video when the currentVideoIndex changes
    const player = playerRefs.current[currentVideoIndex];
    if (player && player.getInternalPlayer) {
      const internalPlayer = player.getInternalPlayer();
      if (internalPlayer) {
        internalPlayer
          .play()
          .catch((error) => console.error("Auto-play error:", error));
      }
    }
  }, [currentVideoIndex]);

  const handleBuffer = () => setLoading(true);
  const handleBufferEnd = () => setLoading(false);

  const getYesterdayDate = () => {
    let currentDate = new Date();
    let yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1); // Get yesterday's date
    return yesterday.toISOString().slice(0, 10); // Return as YYYY-MM-DD string
  };

  const get49thDayDate = () => {
    let currentDate = new Date();
    let date49DaysAgo = new Date(currentDate);
    date49DaysAgo.setDate(currentDate.getDate() - 49); // Get date from 49 days ago
    return date49DaysAgo.toISOString().slice(0, 10); // Return as YYYY-MM-DD string
  };

  // Example usage:
  const yesterdayDate = getYesterdayDate();
  const date49DaysAgo = get49thDayDate();

  const getRequestForApi = (url, method) => {
    return {
      url,
      method,
      headers: {
        Authorization: `Bearer ${isAuthenticated}`,
        "Content-Type": "application/json",
      },
    };
  };

  const fetchLogs = async (NameData) => {
    try {
      setPending(true); // Set pending to true at the start of fetching

      let filteredLogs = [];

      try {
        // Create a request object using getRequestForApi function
        const request = getRequestForApi(
          `${global.GET_LOGS}${
            NameData || Name
          }?fetchDate=${date49DaysAgo}&endDate=${yesterdayDate}`,
          "GET"
        );
        const response = await axios(request);
        if (response?.status === 200 || response?.status === 201) {
          const dataDate = response.data;
          // console.log("dataDate", dataDate);

          const filteredData = dataDate.logs.filter(
            (log) => log.online === false
          );

          const billboardStats = filteredData.reduce((acc, log) => {
            const { billboardName, createdAt } = log;
            const date = createdAt.split("T")[0];

            if (!acc[billboardName]) {
              acc[billboardName] = { dates: {}, totalCount: 0 };
            }

            if (!acc[billboardName].dates[date]) {
              acc[billboardName].dates[date] = 0;
            }

            acc[billboardName].dates[date] += 1;
            acc[billboardName].totalCount += 1;

            return acc;
          }, {});

          const result = Object.keys(billboardStats).map((billboardName) => ({
            billboardName,
            dates: Object.entries(billboardStats[billboardName].dates).map(
              ([date, count]) => ({ date, count })
            ),
            totalCount: billboardStats[billboardName].totalCount,
          }));

          filteredLogs = filteredLogs.concat(filteredData);
        } else {
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }

      dispatch(action(constants.heatMapDetail, filteredLogs));
      localStorage.setItem("logsData", JSON.stringify(filteredLogs));
      localStorage.setItem(
        "logsFetchDate",
        new Date().toISOString().slice(0, 10)
      ); // Store current date
    } catch (error) {
      // Display an error toast if fetching logs fails
      toast.error("Failed to fetch logs.");
    } finally {
      setPending(false); // Set pending to false after all requests are processed
    }
  };

  const shouldFetchLogs = () => {
    const lastFetchDate = localStorage.getItem("logsFetchDate");
    const currentDate = new Date().toISOString().slice(0, 10);

    return lastFetchDate !== currentDate;
  };

  useEffect(() => {
    if (shouldFetchLogs()) {
      fetchLogs();
    } else {
      const logsData = JSON.parse(localStorage.getItem("logsData"));
      dispatch(action(constants.heatMapDetail, logsData));
    }
  }, []);

  const scheduleDailyFetch = () => {
    schedule.scheduleJob("47 11 * * *", () => {
      fetchLogs();
    });
  };

  useEffect(() => {
    scheduleDailyFetch();
  }, []);
  return (
    <>
      {pending ? (
        <CustomLoader />
      ) : (
        <>
          <Typography
            variant="h1"
            sx={{
              fontSize: "30px",
              color: "#fff",
              fontWeight: "600",
              mb: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "@media(max-width:992px)": {
                fontSize: "24px",
              },
            }}
          >
            <Box>
              <IconButton LinkComponent={Link} to={`/billboard?id=${id}`}>
                <SVG.ArrowRight />
              </IconButton>{" "}
              <span>{t("Billboard_index.typography.billboards")}</span>
            </Box>

            <Box sx={{ display: matches ? "inlibe-flex" : "none" }}>
              <Button
                sx={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  background: "#322E27",
                  color: "#fff",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  "&:hover": {
                    background: "#322E27",
                    color: "#fff",
                  },
                }}
                LinkComponent={Link}
                to={`/billboard/details/edit`}
                onClick={() =>
                  dispatch(action(constants.billboardName, NameData || Name))
                }
              >
                <ModeEditOutlinedIcon /> <span>Edit</span>
              </Button>
            </Box>
          </Typography>
          <Box sx={{ mb: 3 }}>
            {!cameraBillBoard?.length > 0 ? (
              <FidgetSpinner />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={4}>
                  <Select
                    sx={{
                      "&.MuiInputBase-root": {
                        border: "1px solid #687588",
                        fontSize: "15px",
                        width: "100%",
                        borderRadius: "15px",
                        background: "transparent",
                        color: "#fff",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      },
                      "& fieldset": {
                        display: "none",
                      },
                      "& .MuiSelect-select": {
                        paddingRight:
                          localStorage.getItem("i18nextLng") === "ar" &&
                          "14px !important",
                        paddingLeft:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "32px !important"
                            : "14px",
                      },
                      "& .MuiSelect-icon": {
                        color: "#fff",

                        marginRight:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0"
                            : "10px",
                        marginLeft:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "10px"
                            : "0px",
                        right:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "auto"
                            : "0",
                        left:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0"
                            : "auto",
                      },
                    }}
                    value={boardData}
                    onChange={handleChange}
                    displayEmpty
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          backgroundColor: "#494130",
                          color: "#fff",
                        },
                      },
                    }}
                  >
                    {cameraBillBoard?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item}
                          sx={{
                            justifyContent:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "flex-end"
                                : "flex-start",
                          }}
                        >
                          {item.address || "No Address Available"}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={6} lg={3}>
                  <Box
                    sx={{
                      border: "1px solid #687588",
                      fontSize: "15px",
                      width: "100%",

                      borderRadius: "15px",
                      background: "transparent",
                      color: "#fff",
                      padding: "16.5px 14px",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                      fontFamily:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? `"Cairo", sans-serif`
                          : `"Manrope", sans-serif`,
                    }}
                  >
                    {t("Billboard_index.typography.installation_date")}:{" "}
                    {getDateFormat(billBoardDetail?.data?.installationDate)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Box
                    sx={{
                      border: "1px solid #687588",
                      fontSize: "15px",
                      width: "100%",
                      borderRadius: "15px",
                      background: "transparent",
                      color: "#fff",
                      padding: "16.5px 14px",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                      fontFamily:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? `"Cairo", sans-serif`
                          : `"Manrope", sans-serif`,
                    }}
                  >
                    {t("Billboard_index.typography.next_payment")}:{" "}
                    {billBoardDetail?.data?.nextPaymentDate
                      ? moment(billBoardDetail?.data?.nextPaymentDate).format(
                          "DD MMM YYYY"
                        )
                      : "N/A"}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={2}
                  sx={{ display: matches && "none" }}
                >
                  <Button
                    sx={{
                      background: "#fff",
                      color: "#160B01",
                      textTransform: "capitalize",
                      fontSize:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "13px"
                          : "16px",
                      fontFamily:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? `"Cairo", sans-serif`
                          : `"Manrope", sans-serif`,
                      fontWeight: 700,

                      padding:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "15px 14.5px"
                          : "14.5px",
                      borderRadius: "10px",
                      "&:hover": {
                        background: "#fff",
                        color: "#160B01",
                      },
                      "@media(max-width:992px)": {
                        width: "100%",
                      },
                    }}
                    LinkComponent={Link}
                    to={`/billboard/details/edit`}
                    onClick={() =>
                      dispatch(
                        action(constants.billboardName, NameData || Name)
                      )
                    }
                  >
                    {t("Billboard_index.typography.edit_billboard")}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
          {!billBoardDetail?.data ? (
            <FidgetSpinner />
          ) : (
            <Box sx={{ mb: 4 }}>
              <Grid container spacing={2}>
                {billboardCount.map((item) => (
                  <Grid item xs={12} md={3} lg={2} key={item.id}>
                    <Card sx={{ borderRadius: "5px" }} elevation={0}>
                      <CardContent
                        sx={{
                          background: "#322E27",
                          color: "#fff",
                          p: 1,
                          "&:last-child": { pb: 1 },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "100%",
                              display: "inline-flex",
                              alignItems: "center",
                              background: "#494130",
                              justifyContent: "center",
                            }}
                          >
                            <item.icon />
                          </Box>
                          <Box
                            sx={{
                              flex: 1,
                              "& p": {
                                fontSize: "10px",
                                m: 0,
                              },
                            }}
                          >
                            <Box
                              component={"h3"}
                              sx={{
                                fontSize: "30px",
                                m: 0,
                              }}
                            >
                              {item.title}
                            </Box>
                            <p>{item.label}</p>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              {!adsVideo ? (
                <FidgetSpinner />
              ) : (
                <Grid item xs={12} md={3} lg={3}>
                  <Card
                    sx={{
                      borderRadius: "16px",
                      width: "100%",
                      // minHeight: "calc(100% - 0px)",
                      background: "#322E27",
                    }}
                    elevation={0}
                  >
                    <CardContent
                      sx={{
                        background: "#322E27",
                        color: "#fff",
                        p: 2,
                        pt: 3,
                        "&:last-child": { pb: 2 },
                      }}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: "30px",
                          color: "#fff",
                          fontWeight: "600",
                          mb: 2,
                          "@media(max-width:992px)": {
                            fontSize: "22px",
                          },
                        }}
                      >
                        {t("Billboard_index.typography.billboard_ads")}
                      </Typography>
                      {Name ? (
                        <div
                          style={{
                            borderRadius: "8px",
                            overflow: "hidden",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "12px",
                              overflow: "hidden",
                              "& .react-player": {
                                "@media(max-width:992px)": {
                                  width: "100% !important",
                                  height: "100% !important",
                                },
                              },
                              "& video": {
                                borderRadius: "12px",
                              },
                            }}
                          >
                            {!videoError ? (
                              <ReactPlayer
                                className="react-player"
                                url={adsVideo}
                                width="100%"
                                height="100%"
                                controls={false}
                                playing={true}
                                loop={true}
                                muted={true}
                                style={{
                                  borderRadius: "12px",
                                }}
                                config={{
                                  file: {
                                    attributes: {
                                      controlsList: "nodownload",
                                    },
                                    tracks: [
                                      {
                                        kind: "subtitles",
                                        src: "subtitles.vtt",
                                        srcLang: "en",
                                        label: "English",
                                      },
                                    ],
                                  },
                                }}
                                onError={() => setVideoError(true)}
                              />
                            ) : (
                              <div className="centered-div">
                                {t(
                                  "Billboard_index.typography.no_data_available"
                                )}
                              </div>
                            )}
                          </Box>
                          {/* Video index indicators */}
                        </div>
                      ) : (
                        <FidgetSpinner />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}
              <Grid item xs={12} md={9} lg={9}>
                <Card
                  sx={{
                    borderRadius: "16px",
                    minHeight: "100%",
                    background: "#322E27",
                  }}
                  elevation={0}
                >
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,
                      "&:last-child": { pb: 2 },
                      height: "100%", // Set the height to fill the parent container
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "30px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                        "@media(max-width:992px)": {
                          fontSize: "22px",
                        },
                      }}
                    >
                      {t("Billboard_index.typography.views_and_impressions")}
                    </Typography>
                    {billBoardDetail?.data?.views_impression ? (
                      Object.keys(billBoardDetail?.data?.views_impression)
                        .length > 0 ? (
                        <BarChart />
                      ) : (
                        <div className="centered">
                          {t("Billboard_index.typography.no_data_available")}
                        </div>
                      )
                    ) : (
                      <FidgetSpinner />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Card sx={{ borderRadius: "5px" }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,

                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                      }}
                    >
                      {t("Billboard_index.typography.offline_heat_map")}
                    </Typography>
                    <SevenBySevenGrid
                      loading={pending}
                      setLoading={setPending}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Card sx={{ borderRadius: "5px" }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,

                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                      }}
                    >
                      {t("Billboard_index.typography.billboard_location")}
                    </Typography>
                    <MapComponent />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Card sx={{ borderRadius: "5px", height: "99%" }} elevation={0}>
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      minHeight: "100%",
                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                      }}
                    >
                      {t("Billboard_index.typography.interaction")}
                    </Typography>
                    <Stack direction={"column"} spacing={2}>
                      <Stack
                        onClick={handleQRCode}
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        sx={{
                          width: "180px",
                          background: "#292825",
                          borderRadius: "15px",
                          cursor: "pointer",
                          px: 3,
                          py: 2,
                        }}
                      >
                        <img
                          src={qr}
                          alt=""
                          style={{
                            width: "50px",
                            height: "50px",
                          }}
                        />
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {t("Billboard_index.typography.qr_code")}
                        </Box>
                      </Stack>
                      {/* <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                        sx={{
                          width: "180px",
                          background: "#292825",
                          borderRadius: "15px",
                          cursor: "pointer",
                          px: 3,
                          py: 2,
                        }}
                        onClick={handleSocial}
                      >
                        <img
                          src={link}
                          alt=""
                          style={{ width: "50px", height: "50px" }}
                        />
                        <Box sx={{ fontSize: "14px", fontWeight: "500" }}>Link</Box>
                      </Stack> */}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Card
                  sx={{ borderRadius: "5px", height: "100%" }}
                  elevation={0}
                >
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,
                      minHeight: "100%",
                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                      }}
                    >
                      {t("Billboard_index.typography.remarks")}
                    </Typography>
                    {Remarks && Remarks.length > 0 ? (
                      <SimpleBar style={{ maxHeight: 300 }}>
                        {Remarks.map((remark, index) => (
                          <Box
                            key={index}
                            sx={{
                              background: "#494130",
                              p: 2,
                              borderRadius: "5px",
                              mb: 2,
                            }}
                          >
                            <Box
                              component={"span"}
                              sx={{
                                color: "#fff",
                                fontSize: "10px",
                                mb: 1,
                                display: "inline-block",
                              }}
                            >
                              <b>Date:</b>{" "}
                              {getDateWithTimeFormat(remark.createdAt)}
                            </Box>
                            <Box
                              component={"p"}
                              sx={{
                                fontSize: "12px",
                                fontWeight: "600",
                                m: 0,
                                mb: 1,
                              }}
                            >
                              {remark?.remark}
                            </Box>
                          </Box>
                        ))}
                      </SimpleBar>
                    ) : (
                      <div className="centered-div">
                        {" "}
                        {t("Billboard_index.typography.no_data_available")}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Card
                  sx={{ borderRadius: "5px", height: "100%" }}
                  elevation={0}
                >
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,
                      minHeight: "100%",
                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                      }}
                    >
                      {t("Billboard_index.typography.payments")}
                    </Typography>
                    {billBoardDetail?.data?.rentPayments &&
                    billBoardDetail?.data?.rentPayments.length > 0 ? (
                      <SimpleBar style={{ maxHeight: 300 }}>
                        {billBoardDetail?.data?.rentPayments &&
                          billBoardDetail?.data?.rentPayments
                            .reverse()
                            .map((pay, index) => (
                              <Box
                                key={index}
                                sx={{
                                  background: "#494130",
                                  p: 2,
                                  borderRadius: "5px",
                                  mb: 2,
                                }}
                              >
                                <Box
                                  component={"span"}
                                  sx={{
                                    color: "#fff",
                                    fontSize: "10px",
                                    mb: 0.5,
                                    display: "inline-block",
                                  }}
                                >
                                  <b>
                                    {t("Billboard_index.date_format.date")}:
                                  </b>{" "}
                                  {getDateFormat(pay.date)}
                                </Box>
                                <Stack
                                  direction={"row"}
                                  spacing={2}
                                  alignItems={"center"}
                                >
                                  <Box
                                    sx={{
                                      fontWeight: "700",
                                      fontSize: "12px",
                                      width: "130px",
                                    }}
                                  >
                                    {t("Billboard_index.formats.amount")} :{" "}
                                    {pay.amount}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontWeight: "700",
                                      fontSize: "12px",
                                    }}
                                  ></Box>
                                </Stack>
                              </Box>
                            ))}
                      </SimpleBar>
                    ) : (
                      <div className="centered-div">
                        {" "}
                        {t("Billboard_index.typography.no_data_available")}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Card
                  sx={{ borderRadius: "5px", height: "100%" }}
                  elevation={0}
                >
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      p: 2,
                      pt: 3,
                      minHeight: "100%",
                      "&:last-child": { pb: 2 },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: "15px",
                        color: "#fff",
                        fontWeight: "600",
                        mb: 2,
                      }}
                    >
                      {t("Billboard_index.typography.spot_ad_daily")}
                    </Typography>
                    {spotsPerDay && spotsPerDay.length > 0 ? (
                      <SimpleBar style={{ maxHeight: 300 }}>
                        {spotsPerDay?.reverse()?.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              background: "#494130",
                              p: 2,
                              borderRadius: "5px",
                              mb: 2,
                            }}
                          >
                            <Box
                              component={"span"}
                              sx={{
                                color: "#fff",
                                fontSize: "10px",
                                mb: 0.5,
                                display: "inline-block",
                              }}
                            >
                              <b>{t("Billboard_index.date_format.date")}:</b>{" "}
                              {getDateWithTimeFormat(item.date)}
                            </Box>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              alignItems={"center"}
                            >
                              <Box
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "12px",
                                  width: "140px",
                                }}
                              >
                                {t(
                                  "Billboard_Edit.BillboardTraffic.labels.spotsPerAdPerDay"
                                )}
                              </Box>
                              <Box
                                sx={{
                                  fontWeight: "700",
                                  fontSize: "12px",
                                }}
                              >
                                {isIntegerValue(item.spot_per_Ad_per_day)}
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                color: "#fff",
                                fontSize: "10px",
                                mt: 0.5,
                              }}
                            >
                              {t("Billboard_index.typography.activeAd")}:{" "}
                              {item.ads}
                            </Box>
                          </Box>
                        ))}
                      </SimpleBar>
                    ) : (
                      <div className="centered-div">
                        {" "}
                        {t("Billboard_index.typography.no_data_available")}
                      </div>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Card sx={{ borderRadius: "5px", height: "100%" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  pt: 3,
                  minHeight: "100%",
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t("Billboard_index.typography.gallery")}
                </Typography>

                {!fileName && !fileName?.length > 0 ? (
                  <FidgetSpinner />
                ) : (
                  <GallerySlider fileName={fileName} />
                )}
              </CardContent>
            </Card>
          </Box>

          <Modal open={QRCode} handleClose={handleClose}>
            <Box
              sx={{
                width: "580px",
                p: 3,
                "@media(max-width:1000px)": {
                  width: "100%",
                },
              }}
            >
              <Box
                sx={{
                  p: 2,
                  textAlign: "center",
                  width: "100%",
                }}
                ref={qrCodeRef}
              >
                <QRCodeCanvas
                  id="qr-code"
                  value={`${clientUrl}${billBoardDetail?.data?._id}`}
                  style={{ width: "250px", height: "250px" }}
                  size={qrSize}
                  marginSize={4}
                  includeMargin={true}
                />
              </Box>
              <Box sx={{ mt: 1, textAlign: "center" }}>
                <Link
                  to={`${clientUrl}${billBoardDetail?.data?._id}`}
                  style={{
                    color: "#0792C0",
                    textDecoration: "none",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Billboard_index.typography.click_here_to_open_link")}
                </Link>
              </Box>
              <Stack
                direction={"row"}
                spacing={2}
                useFlexGap
                flexWrap={"wrap"}
                justifyContent={"center"}
                sx={{ mt: 1 }}
              >
                {["300*300", "400*400", "500*500"].map((item) => (
                  <Button
                    key={item}
                    sx={{
                      background: "#222943",
                      borderRadius: "10px",
                      color: "#fff",
                      textTransform: "capitalize",
                      width: "135px",
                      "&:hover": {
                        background: "#222943",
                        color: "#fff",
                      },
                    }}
                    onClick={() => {
                      setQrSize(parseInt(item?.split("*")[0]));
                    }}
                  >
                    {item}
                  </Button>
                ))}
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 4,
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "210px",
                    background:
                      "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)",
                    color: "#fff !important",
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                  onClick={() => handleDownload()}
                >
                  <SVG.Download />{" "}
                  <span>{t("Billboard_index.typography.download")}</span>
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={Social} handleClose={handleClose}>
            <Box
              sx={{
                width: "480px",
                p: 4,
                "@media(max-width:768px)": {
                  width: "280px",
                },
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
              >
                <TextField
                  fullWidth
                  value={`${global.BASE_URL}/${snapData?.snap_id}`}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#fff !important",
                      fontWeight: "600",
                      color: "#000000",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                    "& .MuiInputBase-input": {
                      color: "#000000",
                    },
                  }}
                />
                <IconButton onClick={handleCopy}>
                  <SVG.Copy />
                </IconButton>
                <ToastContainer position="top-right" autoClose={2000} />
              </Stack>

              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                sx={{ mt: 3 }}
              >
                <Link to="/billboard">
                  <img src={facebook} alt="" />
                </Link>
                <Link to="/billboard">
                  <img src={whatsApp} alt="" />
                </Link>
                <Link to="/billboard">
                  <img src={Linkdin} alt="" />
                </Link>
                <Link to="/billboard">
                  <img src={Email} alt="" />
                </Link>
                <Link to="/billboard">
                  <img src={Tel} alt="" />
                </Link>
              </Stack>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
