import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import RangeDatePiker from "../../components/range-date-piker";
import { SVG } from "../../icon/svg.file";
import TicketMessage from "./ticket-message";
import Details from "./Details";
import { useDispatch, useSelector } from "react-redux";
import action from "../../new redux/Action";
import constants from "../../new redux/Types/actionTypes";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  SocketContext,
  useSocket,
} from "../../utility-files/socket/socket.provider";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { useDebounce } from "../../utility-files/custom-hooks/useSearch";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FidgetSpinner } from "react-loader-spinner";

export default function AllTickets({ filter, ticketCount }) {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const [age, setAge] = React.useState("");
  const [query, setQuery] = useSearchParams();
  const [ticketTabs, setTicketTabs] = useState(query.get("ticketTabs") ? 1 : 2);
  const dispatch = useDispatch();
  const activeRoom = query.get("ticket");
  const [search, setSearch] = useState(query.get("search") || "");
  const debouncedSearch = useDebounce(search, 500); // Debounce search input
  const update = query.get("update");
  const [newMsg, setNewMsg] = useState(null);
  const [filters, setFilters] = useState([]);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const tickets = useSelector((state) => state.ticketsList);
  const ticket = useSelector((state) => state.ticket);
  const lastTextDate = useSelector((state) => state.lastTextDate);
  const socket = useSocket();
  const [msgloader, setMsgLoader] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(
    query.get("ticket") || null
  );
  const loadBillboards = useSelector((state) => state.cameraBillBoard);
  const userData = useSelector((state) => state.UserData);
  const billboards = userData.data?.doc?.billboards || [];
  const selectedTicketDetails = useSelector((state) => state.selectedTicket);
  const toggle = useSelector((state) => state.mobileViewTicketToggle);
  const { setIsUnreadMsgs } = useContext(SocketContext);
  const billboardFilter = query.get("billboardFilter");
  const billboardName = useSelector((state) => state.billboardName);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 25,
    totalPages: 1,
    hasMore: true,
  });
  const [isLoading, setIsLoading] = useState(false); // Loading indicator

  const handleTacket = (id) => {
    setTicketTabs(id);
  };

  const handleToggle = (value) => {
    // console.log("toggle", toggle, toggle === false);
    dispatch(action(constants.mobileViewTicketToggle, value));
    // setToggle(value);
  };
  // console.log("newMsg", newMsg);

  useEffect(() => {
    return () => {
      dispatch(action(constants.mobileViewTicketToggle, false));
    };
  }, []);

  useEffect(() => {
    if (["unapprove", "archieve", "disapprove"].includes(filter)) {
      setTicketTabs(2);
      // console.log("<AllTickets> filter- Ticket Tabs ", toggle);
    }
    // dispatch(action(constants.mobileViewTicketToggle, false));
  }, [filter]);

  // Setting default ticket query
  useEffect(() => {
    query.set("ticket", ticket);
    setQuery(query);
  }, [ticket]);

  const getBoardCamera = async () => {
    setPending(true);
    let request = getRequestForApi(
      global.GET_BILLBOARD_CAMERA + `${userData?.data?.doc?.address?.country}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          if (response?.data?.data?.docs) {
            let dataList = response?.data?.data?.docs;
            dispatch(action(constants.cameraBillBoard, dataList));
          }
        }
      })
      .catch((err) => {
        setPending(false);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const displayDate = (date) => {
    const currentDate = moment();
    const otherDate = moment(date);
    const diffInDays = currentDate.diff(otherDate, "days");
    let dateFormat;

    if (diffInDays < 1) {
      dateFormat = moment(date).format("HH:mm");
    } else {
      dateFormat = moment(date).format("DD/MMM/YY");
    }
    return dateFormat;
  };

  const updateTicketDetails = async (id) => {
    setLoading(true);

    const isAvailable = tickets.data?.find((ticket) => ticket.ticket === id);
    // console.log("<AllTIckets> tickets", tickets.data);
    // console.log("<AllTIckets> id", id);

    if (!isAvailable) {
      // dispatch(action(constants.selectedTicket, undefined));
      return;
    }

    const request = getRequestForApi(
      `${global.GET_TICKET_DETAILS}/${id}`,
      methodType.GET
    );

    try {
      const { data } = await callHttpRequest(request);
      socket?.emit("mark", id);

      dispatch(action(constants.selectedTicket, data));

      dispatch(
        action(constants.ticketsList, {
          ...tickets,
          data: (tickets.data || []).map((room) => {
            if (room.tickets[0]._id === id) {
              room.unmarkmsgcount = 0;
            }
            return room;
          }),
        })
      );
    } catch (error) {
      console.error(
        "[updateTicketDetails] Error fetching ticket details:",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const updateTickets = async (addMoreTickets = false) => {
    if (update) {
      query.delete("update");
      setQuery(query);
    }
    const page = addMoreTickets ? pagination.page : 1;
    if (!addMoreTickets) {
      setPending(true);
    }

    const request = getRequestForApi(
      `${global.GET_ALL_ROOMS}?filter=${filter}&search=${debouncedSearch}&page=${page}&limit=${pagination.limit}`,
      methodType.GET
    );

    try {
      const { data } = await callHttpRequest(request);
      const hasMore = data.data.length >= pagination.limit;

      const updatedTickets = addMoreTickets
        ? [...(tickets.data || []), ...data.data]
        : data.data;

      dispatch(
        action(constants.ticketsList, { ...tickets, data: updatedTickets })
      );

      setPagination((prev) => ({ ...prev, hasMore }));

      if (!updatedTickets.find((item) => item.tickets[0]._id === ticket)) {
        const firstTicketId = updatedTickets[0]?.tickets[0]._id;
        dispatch(action(constants.ticket, firstTicketId));
        updateTicketDetails(firstTicketId);
      }
    } catch (error) {
      console.error("[updateTickets] Error fetching tickets:", error);
    } finally {
      setPending(false);
      setIsLoading(false);
    }
  };

  const getAllChats = async (date) => {
    setMsgLoader(true);
    if (!ticket) return;
    const dateFilter = date !== "" ? lastTextDate : date;
    // console.log("date", lastTextDate);
    let request;
    request = getRequestForApi(
      global.GET_CHAT_LIST + `/${ticket}?limit=10&date=${dateFilter || ""}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then(({ data }) => {
        dispatch(
          action(constants.chats, {
            data: data.data.reverse(),
            isLoading: false,
            hasMore: data.results !== 0,
          })
        );
        dispatch(
          action(
            constants.lastTextDate,
            data.data[data.data.length - 1].createdAt
          )
        );
        //  setLastTextDate(data.data[data.data.length - 1].createdAt);
      })
      .catch(() => {
        // setChats((prev) => ({
        //   data: prev.data,
        //   isLoading: false,
        //   hasMore: false,
        // }));
      })
      .finally(() => setMsgLoader(false));
  };

  useEffect(() => {
    updateTickets();
    const similarList = tickets?.data
      ? tickets.data[0].tickets[0].status === filter
      : false;
    if (!similarList) {
      setPagination((prev) => ({ ...prev, page: 1 }));
    }
  }, [filter, update, debouncedSearch]);

  useEffect(() => {
    if (ticket) {
      updateTicketDetails(ticket);
      getAllChats("");
    }
  }, [ticket]);

  //receiveMessage
  useEffect(() => {
    socket?.on("receiveMessage", setNewMsg);
    socket?.on("REFRESH", updateTickets);
    return () => {
      socket?.off("receiveMessage", setNewMsg);
    };
  }, [socket]);

  useEffect(() => {
    if (userData.data && !loadBillboards.length) getBoardCamera();
  }, [userData, loadBillboards]);

  const handleChange = async (event) => {
    const billboard = event?.target?.value || event;
    // console.log("[AllTickets]<handleChange>billboard", billboard);
    setAge(billboard);
    setPending(true);
    const request = getRequestForApi(
      `${global.GET_ALL_ROOMS}?filter=${filter}&search=${debouncedSearch}&billboard=${billboard}`,
      "GET"
    );

    try {
      const { data } = await callHttpRequest(request);
      dispatch(action(constants.ticketsList, data));

      let inTickets = false;
      data.data.forEach((item) => {
        let user = { room: item.tickets[0]._id };
        if (ticket === item.ticket) {
          inTickets = true;
        }

        socket?.emit("join", user);
        // console.log("<AllTickets> handle change--> User Joined");
      });

      if (inTickets) {
        updateTicketDetails(ticket);
      } else {
        const firstTicketId = data?.data[0]?.tickets[0]._id;
        dispatch(action(constants.ticket, firstTicketId));
        updateTicketDetails(firstTicketId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (billboardFilter === "true" && billboardName) {
      handleChange(billboardName);
    }
  }, [billboardFilter]);

  useEffect(() => {
    if (!newMsg) return;

    if (ticket === newMsg?.room) {
      socket?.emit("mark", ticket);
      // console.log("<AllTickets> mark -- ", newMsg.message, tickets);
      dispatch(
        action(constants.ticketsList, {
          ...tickets,
          data: (tickets.data || []).map((room) => {
            if (room.tickets[0]._id === newMsg?.room) {
              room.recentmsg = {
                message: newMsg.message,
                createdAt: newMsg.createdAt,
                user: newMsg.user._id,
              };
              room.resent_msg_by = [newMsg.user];
            }
            return room;
          }),
        })
      );
    } else {
      dispatch(
        action(constants.ticketsList, {
          ...tickets,
          data: (tickets.data || []).map((room) => {
            if (room.tickets[0]._id === newMsg?.room) {
              room.recentmsg = {
                message: newMsg.message,
                createdAt: newMsg.createdAt,
                user: newMsg.user._id,
              };
              room.resent_msg_by = [newMsg.user];
              room.unmarkmsgcount = (room.unmarkmsgcount || 0) + 1;
            }
            return room;
          }),
        })
      );
    }
    updateTickets();
  }, [newMsg]);

  useEffect(() => {
    const visible = matches ? (!toggle ? "block" : "none") : "none";
    // console.log("[AllTickets] Visibility", visible);
    if (pagination.page > 1) updateTickets(true);
  }, [pagination.limit, pagination.page]);

  // Handle scrolling to load more tickets
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      pagination.hasMore &&
      !isLoading
    ) {
      console.log(
        "[AllTickets]<handleScroll>",
        scrollTop + clientHeight,
        scrollHeight - 5,
        scrollTop + clientHeight >= scrollHeight - 5
      );
      setIsLoading(true);
      const currentpage = pagination.page;
      setPagination((prev) => ({ ...prev, page: currentpage + 1 }));
    }
  };
  return (
    <>
      {pending ? (
        <CustomLoader />
      ) : (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={5}
              sx={{ display: matches ? (!toggle ? "block" : "none") : "block" }}
            >
              <Card
                sx={{
                  borderRadius: "16px",
                  height: matches ? "calc(100vh - 295px)" : null,
                  overflow: matches ? "auto" : null,
                  overflowX: matches ? "hidden" : null,
                }}
                elevation={0}
                onScroll={matches ? handleScroll : null}
              >
                <CardContent
                  sx={{
                    background: "#322E27",
                    color: "#fff",
                    minHeight: "100%",
                    p: 2,
                    "&:last-child": { pb: 2 },
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={
                      localStorage.getItem("i18nextLng") === "ar" ? 0 : 1
                    }
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                      mb: 2,
                      "& .MuiInputBase-root": {
                        border: "1px solid #687588",
                        fontSize: "15px",
                        width: "100%",
                        borderRadius: "15px",
                        background: "#282520",
                        color: "#fff",
                      },
                      "& fieldset": {
                        display: "none",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#687588",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={t("Ticket_index.common.labels.search")}
                      // label={t("Ticket_index.common.labels.search")}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiFormControl-root": {
                          marginLeft:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "10px"
                              : "0",
                        },
                        "& .MuiInputBase-root": {
                          background: "transparent !important",
                          borderRadius: "10px",
                          border: "1px solid #687588",

                          color: "#fff",
                          input: {
                            "&::placeholder": {
                              color: "#687588",
                              opacity: 1,
                              fontFamily:
                                localStorage.getItem("i18nextLng") === "ar"
                                  ? `"Cairo", sans-serif`
                                  : `"Manrope", sans-serif`,
                            },
                          },
                        },
                      }}
                    />
                    {billboards && loadBillboards && (
                      <Select
                        defaultValue="Billboard Sort"
                        value={age}
                        onChange={handleChange}
                        sx={{
                          width: "100%",
                          "& .MuiSelect-select": {
                            paddingRight:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "14px !important",
                            paddingLeft:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "32px !important"
                                : "14px",
                          },
                          "& .MuiSelect-icon": {
                            color: "#fff",
                            top: "26px",
                            marginRight:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "0"
                                : "10px",
                            marginLeft:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "10px"
                                : "0px",
                            right:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "auto"
                                : "0",
                            left:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "0"
                                : "auto",
                          },
                        }}
                        displayEmpty
                        IconComponent={SVG.ArrowDown}
                      >
                        <MenuItem
                          key={""}
                          value={""}
                          // className="droppop-slect"
                        >
                          {t("Ticket_index.common.labels.billboardSort")}
                        </MenuItem>
                        {billboards
                          .sort((a, b) => {
                            const extractNumber = (name) =>
                              parseInt(name.replace(/\D/g, ""));

                            const isMGroup = (name) => /M/.test(name);

                            const aIsMGroup = isMGroup(a);
                            const bIsMGroup = isMGroup(b);

                            if (aIsMGroup && !bIsMGroup) {
                              return 1; // b comes before a
                            }
                            if (!aIsMGroup && bIsMGroup) {
                              return -1; // a comes before b
                            }

                            const aNumber = extractNumber(a);
                            const bNumber = extractNumber(b);

                            return aNumber - bNumber;
                          })
                          .map((item) => {
                            const billboardData = loadBillboards?.find(
                              (loadBillboard) =>
                                item === loadBillboard.billboardName
                            );
                            if (billboardData) {
                              return (
                                <MenuItem
                                  key={item}
                                  value={item}
                                  className="droppop-slect"
                                >
                                  <ListItemText
                                    primary={`${billboardData?.address || ""}`}
                                  />
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    )}
                    {/* <RangeDatePiker /> */}
                  </Stack>

                  <Stack
                    direction="column"
                    divider={
                      <Divider
                        sx={{ borderColor: "#A0AEC0" }}
                        orientation="horizontal"
                        flexItem
                      />
                    }
                    spacing={2}
                    sx={{
                      height: "calc(100vh - 395px)",
                      overflow: "hidden",
                      overflowY: "auto",
                      pr: localStorage.getItem("i18nextLng") === "ar" ? 0 : 2,
                      pl: localStorage.getItem("i18nextLng") === "ar" ? 2 : 0,
                      "@media(max-width:992px)": {
                        height: "auto",
                      },
                    }}
                    onScroll={handleScroll}
                  >
                    {tickets.data &&
                      tickets?.data
                        ?.sort((a, b) => {
                          const dateA = a.recentmsg?.createdAt || "";
                          const dateB = b.recentmsg?.createdAt || "";
                          return new Date(dateB) - new Date(dateA);
                        })
                        .map((index) => (
                          <Box
                            key={index.tickets[0]._id}
                            onClick={() => {
                              // console.log("<AllTickets> onclick ");
                              handleToggle(true);
                              setLoading(true);
                              setSelectedTicket(index.tickets[0]._id);
                              dispatch(
                                action(constants.ticket, index.tickets[0]._id)
                              );
                              setQuery({
                                id: 1,
                                ticketTabs: 1,
                                ticket: index.tickets[0]._id,
                              });
                              // socket?.emit("join", {
                              //   room: index.tickets[0]._id,
                              // });
                              setLoading(false);
                            }}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                              padding: "10px",
                              borderRadius: "10px",
                              transition: "background-color 0.3s ease",
                              "&:hover": {
                                backgroundColor: "#3a3a3a",
                              },
                              // "&.selected": {
                              //   backgroundColor: "#3a3a3a",
                              // },
                              backgroundColor:
                                selectedTicket === index.tickets[0]._id
                                  ? "#4a4a4a"
                                  : "transparent",
                            }}
                          >
                            <Stack
                              direction={"row"}
                              spacing={2}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Box
                                component={"span"}
                                sx={{
                                  color: "#CBD5E0",
                                }}
                              >
                                Ticket Id: {index.tickets[0]._id.substr(-5)}
                              </Box>

                              {index.recentmsg ? (
                                <small className="float-end ago">
                                  {index.recentmsg
                                    ? displayDate(index.recentmsg?.createdAt)
                                    : ""}
                                  {(index.tickets[0].status === "approve" ||
                                    index.tickets[0].status === "close") &&
                                  index.unmarkmsgcount > 0 &&
                                  index.tickets[0]._id !== ticket ? (
                                    <Typography
                                      variant="body2"
                                      color="primary"
                                      noWrap
                                      sx={{
                                        width: "20px",
                                        borderRadius: "50%", // Kept borderRadius for circular shape
                                        backgroundColor: "orange", // Use backgroundColor instead of background
                                        color: "#fff",
                                        display: "flex", // Changed from inline-flex to flex
                                        fontWeight: "500",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "12px",
                                        zIndex: 1, // Ensures it appears above other content
                                      }}
                                    >
                                      {index.unmarkmsgcount > 0 &&
                                        index.unmarkmsgcount}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                  {index.tickets[0]._id === ticket ||
                                  index.tickets[0]._id === ticket ? (
                                    <Box
                                      sx={{
                                        width: "10px",
                                        height: "10px",
                                        borderRadius: "50%",
                                        backgroundColor: "#34A853",
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </small>
                              ) : (
                                <Box
                                  component={"span"}
                                  sx={{
                                    color: "#CBD5E0",
                                    display: "inline-block",
                                    ml: "auto",
                                  }}
                                >
                                  {moment(index.tickets[0].createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </Box>
                              )}
                            </Stack>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              spacing={2}
                            >
                              <Box
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                  letterSpacing: "0.3px",
                                }}
                              >
                                {index.tickets[0].ticket_name}
                              </Box>
                            </Stack>
                            <span>
                              {index.recentmsg?.message ? (
                                <div className="elps">
                                  <span
                                    style={{
                                      fontWeight: "600",
                                      color: "#3ac4f2",
                                    }}
                                  >
                                    {index.resent_msg_by[0]?.fullname}
                                  </span>
                                  :
                                  {index.recentmsg?.message.length > 33
                                    ? index.recentmsg?.message.substring(
                                        0,
                                        32
                                      ) + "..."
                                    : index.recentmsg?.message}
                                </div>
                              ) : (
                                ""
                              )}
                            </span>
                          </Box>
                        ))}
                    {isLoading && (
                      <Box textAlign="center" mt={2}>
                        Loading...
                      </Box>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              lg={7}
              sx={{
                display: matches
                  ? toggle == true
                    ? "block"
                    : "none"
                  : "block",
              }}
            >
              <Box
                sx={{
                  // marginLeft: matches ? "-16px" : null,
                  marginTop: matches ? "-20px" : null,
                  // marginRight: matches ? "-16px" : null,
                  marginBottom: matches ? "-20px" : null,
                }}
              >
                <Card
                  sx={{
                    borderRadius: {
                      xs: "0px",
                      lg: "16px",
                      marginBottom: matches ? "15px" : "0",
                    },
                    height: "100%",
                  }}
                  elevation={0}
                >
                  <CardContent
                    sx={{
                      background: "#322E27",
                      color: "#fff",
                      height: "100%",

                      p: { xs: "16px", lg: 3 },
                      "&:last-child": { pb: { xs: "16px", lg: 3 } },
                    }}
                  >
                    <Stack
                      direction={{ xs: "column", lg: "row" }}
                      alignItems={{
                        xs: "self-start",
                        lg: "center",
                      }}
                      spacing={0}
                      sx={{ mb: 2 }}
                      justifyContent={"space-between"}
                    >
                      <Typography
                        variant="h1"
                        sx={{
                          fontSize: "30px",
                          color: "#fff",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          gap: "2px",
                          "@media(max-width:992px)": {
                            fontSize: "24px",
                          },
                        }}
                      >
                        {matches && (
                          <IconButton onClick={() => handleToggle(false)}>
                            <ArrowBackIosIcon sx={{ color: "#fff" }} />
                          </IconButton>
                        )}
                        {t("Ticket_allTicket.tickets_title")}
                      </Typography>
                      <Box
                        sx={{
                          // ml: "auto !important",
                          // "@media(max-width:992px)": {
                          //   ml: "0px !important",
                          // },
                          "& span": {
                            borderBottom: "2px solid transparent",
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: "600",
                            px: 2,
                            py: 1,

                            "&:hover": {
                              background:
                                "linear-gradient(to right, #F7C409 0%, #B44C06 100%)",
                              WebkitBackgroundClip: "text",
                              WebkitTextFillColor: "transparent",
                              borderBottom: "2px solid #B44C06",
                            },
                          },
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems={"center"}
                        >
                          {["unapprove", "disapprove"].includes(filter) ? (
                            ""
                          ) : (
                            <Box
                              component={"span"}
                              onClick={() => handleTacket(1)}
                              sx={{
                                background:
                                  ticketTabs === 1
                                    ? "linear-gradient(to right, #F7C409 0%, #B44C06 100%)"
                                    : "",
                                WebkitBackgroundClip:
                                  ticketTabs === 1 ? "text" : "",
                                WebkitTextFillColor:
                                  ticketTabs === 1 ? "transparent" : "",
                                borderBottom:
                                  ticketTabs === 1
                                    ? "2px solid #B44C06 !important"
                                    : "",
                              }}
                            >
                              {t("Ticket_allTicket.messages_tab")}
                            </Box>
                          )}
                          <Box
                            component={"span"}
                            onClick={() => handleTacket(2)}
                            sx={{
                              background:
                                ticketTabs === 2
                                  ? "linear-gradient(to right, #F7C409 0%, #B44C06 100%)"
                                  : "",
                              WebkitBackgroundClip:
                                ticketTabs === 2 ? "text" : "",
                              WebkitTextFillColor:
                                ticketTabs === 2 ? "transparent" : "",
                              borderBottom:
                                ticketTabs === 2
                                  ? "2px solid #B44C06 !important"
                                  : "",
                            }}
                          >
                            {t("Ticket_allTicket.details_tab")}
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                    {ticketTabs === 1 && <TicketMessage loading={msgloader} />}
                    {ticketTabs === 2 && <Details loading={loading} />}
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
